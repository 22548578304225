import axios from 'axios'
import config from '../../config';
import { imprimir } from '../../helper/util';

const BASE_API = config.BASE_API ;
const ENDERECO_API = config.ENDERECO_API;

const obterCPF = (cpf, antecipar = false) => {
    return axios.get(`${BASE_API}recadastramento/segurado/${cpf}?antecipar=${antecipar}`);
}

const obterRepresentante = (cpf) => {
    return axios.get(BASE_API + 'recadastramento/representante/' + cpf);
}


const retificar = (id) => {
    return axios.get(BASE_API + 'recadastramento/censo/' + id);
}

const getEndereco = (cep, if404Endereco) => {
    return axios.get(ENDERECO_API + 'enderecos/' + cep).then(response => response.data).catch(if404Endereco);
}

const finalizar = (params) => {
   return axios
    // .post(BASE_API + 'recadastramento', params, { responseType: 'blob' });
    .post(BASE_API + 'recadastramento', params);
    
}

const visualizar = (idCenso) => {
   return axios
    .get(BASE_API + 'recadastramento/comprovante/' + idCenso, { responseType: 'blob' })
    .then((resp) => resp ? resp.data : resp)
    .then((data) => {
        imprimir(data);
    });
}

const obterUF = (sigla) => {
    return axios.get(ENDERECO_API + 'ufs/' + sigla);
}

const obterCidades = (uf) => {
    return axios.get(ENDERECO_API + 'municipios?uf=' + uf)
}

const obterPais = () => {
    return axios.get(BASE_API + 'recadastramento/paises');
}

const obterHistorico = (cpf) => {
    return axios.get(BASE_API + 'recadastramento/historico/' + cpf);
}

const obterBairros = (cidade) => {
    return axios.get(ENDERECO_API + 'bairros?municipio_id=' + cidade);
}

const getOpcoes = () => axios.get(BASE_API + 'opcoes');


const getParametros = () => axios.get(`${BASE_API}recadastramento/parametros`);

const getArquivo = (token) => axios.get(`${BASE_API}recadastramento/arquivo/${token}`);

const checkandoArquivoUpload = (fileList) =>{
    return Array.isArray(fileList) && fileList?.length > 0 && fileList[0].uid !== "-1";
}

const mapCertidao = (valores, values, existeBeneficioPensao)=>{
    return existeBeneficioPensao ? {certidao: {
        ...valores.certidao, 
        ...values.certidao
    }} : {};
}

const mapPessoa = (valores, attr)=>{
    if (attr.endereco.bairro.nome) {
        attr.endereco.bairro = attr.endereco.bairro.nome
    }

    return {pessoa: {
            ...valores.pessoa, 
            ...attr.pessoa, 
            ...attr.endereco, 
            ...attr.contato
    }};
}

const mapEndereco = (attr) => {
    return {
        endereco: { 
            bairro: {nome: attr.bairro},
            cep: attr.cep,
            cidade: attr.cidade,
            complemento: attr.complemento,
            logradouro: attr.logradouro,
            pais: attr.pais,
            uf: attr.uf
        }
    };
}


const mapRepresentanteLegal = (representanteLegal, tokenDocumento)=>{
    return representanteLegal ? {representanteLegal: {
            ...representanteLegal, 
            tokenDocumento
    }} : {};
}

const formatarRequisicao = ({certidaoToken, representanteLegal , ...valores}, {pessoa,endereco,contato, ...values}, existeBeneficioPensao) => {
    var data = new FormData();
    var arquivoRepresentante = representanteLegal?.arquivo;
    var arquivoCertidao = values?.certidao?.arquivo;
    var tokenDocumento = representanteLegal?.tokenDocumento;

    if(checkandoArquivoUpload(arquivoRepresentante))
        tokenDocumento = null;
    if(checkandoArquivoUpload(arquivoCertidao))
        certidaoToken = null;
    if(Object.keys(valores?.certidao).length === 0)
        delete valores.certidao;
    data.append('representanteArquivo', checkandoArquivoUpload(arquivoRepresentante) ? arquivoRepresentante[0].originFileObj : null);
    data.append('certidaoArquivo', checkandoArquivoUpload(arquivoCertidao) ? arquivoCertidao[0].originFileObj : null);
    data.append('censo', new Blob([JSON.stringify({
                ...valores, 
                ...mapPessoa(valores, {pessoa,endereco,contato}),
                ...mapCertidao(valores, values, existeBeneficioPensao), 
                ...mapEndereco(endereco), 
                contato,
                ...mapRepresentanteLegal(representanteLegal, tokenDocumento),
                certidaoToken: certidaoToken
            })], {
                type: "application/json"
            })
    );
    return Promise.resolve(data);
}

export default {
    obterCPF,
    obterUF,
    obterCidades,
    obterPais,
    obterBairros,
    getEndereco,
    finalizar,
    obterRepresentante,
    obterHistorico,
    visualizar,
    retificar,
    getOpcoes,
    getParametros,
    getArquivo,
    formatarRequisicao
}