import React from "react";
import { Input, Button } from "antd";
import NumberFormat from "react-number-format";
import { validateBr } from "js-brasil";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const InputCPF = ({ value = "", onSearch, onClear, ...props }) => {
  return (
    <NumberFormat
      {...props}
      value={value}
      onSearch={
        props.readOnly ? onClear : validateBr.cpf(value) ? onSearch : undefined
      }
      customInput={Input.Search}
      format="###.###.###-##"
      enterbutton={
        <Button
          icon={props.readOnly ? <CloseOutlined /> : <SearchOutlined />}
        />
      }
    />
  );
};

InputCPF.Validator = (_, value = "") =>
  value.replace(/\D/g, "").length < 11 || validateBr.cpf(value)
    ? Promise.resolve()
    : Promise.reject(new Error("CPF inválido"));

export default InputCPF;
