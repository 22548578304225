import axios from "axios";
import config from "../../config";

const BASE_API = config.BASE_API;

const salvar = (parametro) => {
  return axios.post(BASE_API + "bloqueios/agendamento/", parametro);
};

const getAgendamento = () => axios.get(BASE_API + "bloqueios/agendamento");

const getReport = (data) =>
  axios.get(
    `${BASE_API}bloqueios/desbloqueados/enviar-email/periodo?dataInicial=${data.dataInicial}&dataFinal=${data.dataFinal}`
  );

const getUnlockDates = () => {
  return {
    dataInicio: "01/02/2002",
    dataFim: "01/03/2005",
  };
};

export default {
  salvar,
  getAgendamento,
  getUnlockDates,
  getReport,
};
