import axios from "axios";
import config from "../config";

const BASE_API = config.BASE_API;

const getReports = (data) => {
  return axios.post(BASE_API + "recadastramento/filter", {
    pagination: data.pagination,
    searchQueries: data.searchQueries,
  });
};

export default {
  getReports,
};
