import React, { useContext } from 'react';
import Header from 'gp-header';
import 'gp-header/dist/index.css';
import Loading from 'gp-loading';
import 'gp-loading/dist/index.css';
import Routes from '../../routes';
import httpService from '../../helper/InterceptorAxios';
import LoadingContext from "../../state/Loading/Context";
import MenuContext from "../../state/Menu/Context";
import { useUsuarioLogado } from '../../component';
import useSecurityContext from '../../component/Security/useSecurityContext';
import config from '../../config';

const nomeSistema = `Censo Previdenciário v${process.env.REACT_APP_VERSION}`;

const Home = () => {

  httpService.InterceptorAxios();

  const { loading} = useContext(LoadingContext);
  const { menu } = useContext(MenuContext);
  const { nome, userName } = useUsuarioLogado();
  const { logout } = useSecurityContext();

  return (
    <>
      <Loading loading={loading} menu={menu} />

      <div className="section-sistema container-fluid m-0 p-0 row">
        <Header clientId="censo" nomeUsuarioLogado={nome} nomeSistema={nomeSistema} urlLogo='images/censo.png' logout={logout} urlSaApi={config.SA_API} cpf={userName} /> 
        <Routes />
      </div>
    </>
  );
};

export default Home;
