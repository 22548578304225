import React from "react";
import { Col, Row, Form, Input } from "antd";
import InputTelefone from "./Input-telefone";

const Contato = ({
    nome,
    ocultarTitulo = false
}) =>{
    return <div>
        <div hidden={ocultarTitulo}>
            <h2 className="legend">Contato(s)</h2>
            <div className="linha-legenda"></div>
        </div>
        <Row>
        <Col span={4}>
            <Form.Item name={[nome, "telefone1"]} label="Telefone" rules={[{required:true, min:14}]}>
                <InputTelefone className="ant-input"/>
            </Form.Item>
        </Col>
        <Col span={4}>
            <Form.Item name={[nome, "telefone2"]} label="Whatsapp" rules={[{min:14}]}>
                <InputTelefone className="ant-input" />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item name={[nome, "email"]} label="E-Mail" rules={[{type:"email"}]}>
                <Input />
            </Form.Item>
        </Col>
      </Row>
    </div>;
};

export default Contato;