import React from "react";
import { Input, Button } from "antd";
import NumberFormat from "react-number-format";
import { validateBr } from "js-brasil";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const InputCPF = ({
  value = "",
  onSearch,
  onClear,
  showSearch = true,
  ...props
}) => {
  if (showSearch) {
    props.onSearch = props.readOnly
      ? onClear
      : validateBr.cpf(value)
      ? onSearch
      : undefined;
  }

  props.enterbutton = (
    <Button icon={props.readOnly ? <CloseOutlined /> : <SearchOutlined />} />
  );

  return (
    <NumberFormat
      {...props}
      value={value}
      customInput={showSearch ? Input.Search : Input}
      format="###.###.###-##"
    />
  );
};

InputCPF.Validator = (_, value = "") =>
  value.replace(/\D/g, "").length < 11 || validateBr.cpf(value)
    ? Promise.resolve()
    : Promise.reject(new Error("CPF inválido"));

export default InputCPF;
