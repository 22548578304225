import React, { useReducer } from 'react';
import MainHomeContext from './Context';
import MainHomeReducer from "./reducer"


const Provider = ({ children }) => {
  const [mainHome, mainHomeDispatch] = useReducer(MainHomeReducer)
  return (
    <MainHomeContext.Provider value={{ mainHome, mainHomeDispatch }}>
      {children}
    </MainHomeContext.Provider>
  );
};

export default Provider;

