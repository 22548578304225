import React from "react";
import { Button, Upload } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import services from "../services";
import { saveAs } from 'file-saver';

const InputUpload = ({
    label,
    valor,
    ...props
})=>{
    const [mostrarBotaoUpload, setMostrarBotaoUpload] = React.useState(true);
    const adicionar = ()=>{ setMostrarBotaoUpload(false); return false; };
    const download = arquivo=> services.getArquivo(arquivo.token).then(response=> saveAs(window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"})), 'Comprovante Representante Legal.pdf'));
    React.useEffect(()=> {if(valor) setMostrarBotaoUpload(false)},[valor]);
    return <Upload listType="picture" showUploadList={{ showDownloadIcon: true, downloadIcon: <DownloadOutlined /> }} onRemove={setMostrarBotaoUpload} beforeUpload={adicionar} onDownload={download} {...props} >
                {mostrarBotaoUpload && <Button icon={<UploadOutlined />}>{label}</Button>}
            </Upload>;
};

InputUpload.normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

export default InputUpload;