import React, { useState, useEffect } from "react";

// import { Container } from './styles';

export const CalendarioRecadastramentoContext = React.createContext({});

export const CalendarioRecadastramentoProvider = (props) => {
  const objeto = [
    { dataInicioVigencia: "2022-01-01", regime: "ambos" },
    { dataInicioVigencia: "2022-01-02", regime: "militar" },
    { dataInicioVigencia: "2022-01-03", regime: "civil" },
    { dataInicioVigencia: "2022-01-04", regime: "militar" },
    { dataInicioVigencia: "2022-01-05", regime: "civil" },
    { dataInicioVigencia: "2022-01-06", regime: "ambos" },
  ];

  const [calendariosCadastrados, setCalendariosCadastrados] = useState(objeto);

  useEffect(() => {
    const calendarioStorage = localStorage.getItem("calendariosCadastrados");

    if (calendarioStorage) {
      setCalendariosCadastrados(JSON.parse(calendarioStorage));
    }
  }, []);

  return (
    <CalendarioRecadastramentoContext.Provider
      value={{ calendariosCadastrados, setCalendariosCadastrados }}
    >
      {props.children}
    </CalendarioRecadastramentoContext.Provider>
  );
};

export const useCalendarioRecadastramento = () =>
  React.useContext(CalendarioRecadastramentoContext);
