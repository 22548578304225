const statusDosBeneficios = {
  ATIVO: {
    nome: "Ativo",
    value: 0,
    sigla: "ATIVO",
    class: "badge-success",
    tooltip: false,
  },
  BLOQUEADO: {
    nome: "Bloqueado",
    value: 1,
    sigla: "BLOQUEADO",
    class: "badge-warning",
    tooltip: true,
  },
  SUSPENSO: {
    nome: "Suspenso",
    value: 2,
    sigla: "SUSPENSO",
    class: "badge-danger",
    tooltip: true,
  },
  CANCELADO: {
    nome: "Cancelado",
    value: 3,
    sigla: "CANCELADO",
    class: "badge-secondary",
    tooltip: true,
  },
  EXTINTO: {
    nome: "Extinto",
    value: 4,
    sigla: "EXTINTO",
    class: "badge-secondary",
    tooltip: true,
  },
};

export default statusDosBeneficios;
