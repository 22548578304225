import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { conformToMask } from 'react-text-mask';

export const MASK_FONE = ["(", /[0-9]/, /[0-9]/, ")", " ",  /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
export const MASK_CPF = [/[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/]
export const MASK_DATE = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
export const MASK_ANO = [/\d/, /\d/, /\d/, /\d/]
export const MASK_DIAMES = [/\d/, /\d/]
export const MASK_MES_ANO = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
export const MASK_CEP = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/]
export const MASK_ZIP_CODE = [/\d/, /\d/,/\d/, /\d/,/\d/, /\d/,/\d/, /\d/,/\d/, /\d/,/\d/, /\d/,/\d/, /\d/,/\d/, /\d/,/\d/, /\d/,/\d/, /\d/, /\d/,/\d/, /\d/,/\d/, /\d/,];
export const MASK_HORA = [/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]

export const removeMask = value => {return value !== '' ? value.replace(/[^0-9]/g, '') : value }
export const removeMaskMonetario = value => { return value !== '' && typeof value === 'string' ? value.replace(/[^0-9/,]/g, '') : value }
export const MASK_PERCENTUAL = (limitDecimal, separador, limitInteger = null) => {
    return createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: separador,
        decimalLimit: limitDecimal,
        integerLimit: limitInteger,
        allowNegative: false,
        allowLeadingZeroes: true,
        requireDecimal: true
    });
}

export const MASK_NUMBER = (limitInteger = null) => {
    return createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: false,
        integerLimit: limitInteger,
        allowNegative: false,
        allowLeadingZeroes: true,
        requireDecimal: false
    });
}


export const MASK_PERCENTUAL_DECIMAL = (value) => {
    let withoutSeparator = value.replace(',', '');
    if (withoutSeparator.indexOf('.') !== -1) {
        withoutSeparator = withoutSeparator.replace('.', '');
    }
    let arrayValue = withoutSeparator.split("");
    let mask = [];
    if (arrayValue.length < 4) {
        for (let index = 1; index <= arrayValue.length; index++) {
            if (index === 2) {
                mask.push(',');
            }
            mask.push(/\d/);
        }
        return mask;
    } else {
        return [/\d/, /\d/, ',', /\d/, /\d/]
    }
}

export const MASK_MONETARIO = createNumberMask({
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    allowNegative: false,
    allowLeadingZeroes: false,
});

export const applyMaskMonetarioThousandSeparator = (monetario) => {
    const monetarioString = applyMaskDouble(monetario);
    let mask = MASK_MONETARIO(monetarioString);
    mask = mask.filter((val) => val !== '[]');
    let retorno = conformToMask(monetarioString, mask, { guide: false });
    return retorno.conformedValue;
}

export const applyMaskDouble = (monetario, qtdCasasDecimais = 2) => {
    if (monetario != null) return monetario.toFixed(qtdCasasDecimais).replace('.', ',');
}

export const applyMaskMonetario = monetario => {
    if (monetario != null) return "R$ " + monetario.toFixed(2).replace('.', ',');
}

export const applyMaskMesAno = mesAno => {
    mesAno = mesAno.toString();
    if (mesAno) return mesAno.replace(/(\d{4})(\d{2})/, "$2/$1");
}

export const applyMaskDATA = data => {
    if (data) {
        data = removeMask(data);
        return data.replace(/(\d{4})(\d{2})(\d{2})/, "$3/$2/$1");
    }
    return '';
}

export const applyMaskMesAnoSemBarra = mesAno => {
    mesAno = mesAno.toString();
    if (mesAno) {
        mesAno = mesAno.replace('/', '');
        return mesAno.replace(/(\d{2})(\d{4})/, "$2$1");
    }
}


export const applyMaskMesAnoPorSeparador = (mesAno, separador) => {
    if (mesAno) {
        mesAno = mesAno.toString();
        return mesAno.replace(/(\d{4})(\d{2})/, "$1" + separador + "$2");
    }
}

export const applyMaskAnoMesDia = (dataString) => {
    let separador = '-';
    dataString = removeMask(dataString);
    dataString = dataString.replace(/(\d{2})(\d{2})(\d{4})/, "$3" + separador + "$2" + separador + "$1");
    return dataString;
}

export const changeVirgulaPerPonto = (value) => {
    value = value === 'string' ? value : value.toString();
    return value.replace(',', '.');
}

export const applyMaskCpf = (cpf) => {
    let mask = MASK_CPF;
    mask = mask.filter((val) => val !== '[]');
    let retorno = conformToMask(cpf, mask, { guide: false });

    return retorno.conformedValue;
}

export const applyMaskDataHora = (dataString) => {
    dataString = removeMask(dataString);

    const mask = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{3})/;
    dataString = dataString.replace(mask, "$3/$2/$1 $4:$5:$6:$7 ");
    return dataString;
}

export const applyMaskHour = (hour) => {
    if (!hour) return MASK_HORA;
    const startsWithTwo = hour[0] === '2'
    const mask = [
        /[0-2]/,
        startsWithTwo ? /[0-3]/ : /[0-9]/,
        ':',
        /[0-5]/,
        /[0-9]/
    ]
    return mask;
}