import React from "react";

import "./style.scss";
import Painel from "gp-painel";
import { applyMaskCpf } from "../../helper/mask";
import "gp-painel/dist/index.css";
import { Secured } from "../../component";
import { Permissions } from "../../Constantes/permissions.constants";
import { Col, Form, Row, Modal, message, Alert, Card } from "antd";
import ModalHistorico from "./modalHistorico";
import {
  Contato,
  Endereco,
  ModalRepresentante,
  IdentificacaoPessoa,
} from "./Componentes/Componentes";
import StatusBeneficios from "../../component/StatusBeneficios";
import { Message } from "../../Constantes/message.constants";
import Parser from "html-react-parser";

import service from "./services";

const statusAlert = {
  warn: { tipo: "warning", titulo: "Aviso" },
};

const Recadastramento = () => {
  const [form] = Form.useForm();
  //
  const [valoresForm, setValoresForm] = React.useState({});
  const [ocultarEtapa2, setOcultarEtapa2] = React.useState(true);
  const [ocultarEtapa3, setOcultarEtapa3] = React.useState(true);
  const [mostrarModalHistorico, setMostrarModalHistorico] =
    React.useState(false);
  const [mostrarModalRepresentante, setMostrarModalRepresentante] =
    React.useState(false);
  const [existeBeneficioPensao, setExisteBeneficioPensao] =
    React.useState(false);
  const [exibeMensagemRepresentante, setExibeMensagemRepresentante] =
    React.useState(false);
  const [requerRepresentante, setRequerRepresentante] = React.useState(false);

  const controleEtapa2 = () => setOcultarEtapa2(false);

  React.useEffect(() => {
    form.setFieldsValue(valoresForm);
    setExisteBeneficioPensao(
      valoresForm?.beneficios?.filter((item) => item.pensao).length > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valoresForm]);

  const handleValoresCPF = (valor) => {
    console.log("OQUE E VALOR", valor);
    setValoresForm(valor);
    setRequerRepresentante(
      IdentificacaoPessoa.checandoDiferencaEntreDatas(valor?.pessoa?.nascimento)
    );
    controleEtapa2(true);
  };

  const noFinishModalRepresentante = (valores) => {
    valoresForm.representanteLegal = valores === null ? null : { ...valores };
    setExibeMensagemRepresentante(true);
    setMostrarModalRepresentante(false);
  };

  const cancelar = () => {
    form.resetFields();
    setValoresForm(null);
    setExibeMensagemRepresentante(false);
    controleEtapa2(false);
    setOcultarEtapa3(true);
    setOcultarEtapa2(true);
  };

  const imprimirComprovante = (idCenso) => {
    service.visualizar(idCenso).catch(console.error);
  };

  const validacaoRepresentante = () => {
    return valoresForm?.representanteLegal == null && requerRepresentante;
  };

  const finalizarRecadastramento = (data) => {
    service
      .formatarRequisicao(valoresForm, data, valoresForm?.requerCertidao)
      .then(service.finalizar)
      .then((response) => {
        if (response) {
          imprimirComprovante(response.data);
          message.success(Message.MSG005_RECADASTRO_SUCESSO, 4);
          setTimeout(cancelar, 4000);
        }
      });
  };

  const confirmarRecadastramento = (data) => {
    setExibeMensagemRepresentante(true);
    if (validacaoRepresentante()) {
      message.error("Representante é Obrigatório");
      return;
    }

    Modal.confirm({
      title: "Confirmação",
      content: `Deseja finalizar o recadastramento de ${data.pessoa.nome} para o ano ${valoresForm?.referencia}?`,
      okText: "Sim",
      onOk: () => finalizarRecadastramento(data),
    });
  };

  const obterCPF = (cpf) => {
    service
      .obterCPF(cpf, true)
      .then((result) => result.data)
      .then((dados) => {
        form.resetFields();
        IdentificacaoPessoa.processarDados(dados, handleValoresCPF);
      });
  };

  return (
    <>
      <Secured
        hasAnyRole={Permissions.RECADASTRAMENTO.TELA}
        isShowErro403={true}
      >
        <Form name="censo" form={form} onFinish={confirmarRecadastramento}>
          <Painel
            titulo="Recadastramento de Benefícios"
            subTitulo="Identificação do Segurado"
          >
            <IdentificacaoPessoa
              nome="pessoa"
              handleValoresCPF={handleValoresCPF}
              onClear={cancelar}
              onHandleRequerRepresentante={setRequerRepresentante}
              desabilitarCampos={!ocultarEtapa2 && ocultarEtapa3}
              desabilitarCPF={!ocultarEtapa2 || !ocultarEtapa3}
              ocultarCampos={ocultarEtapa2}
            />
          </Painel>

          <Painel subTitulo="Benefício(s)" isExibe={!ocultarEtapa2}>
            {valoresForm?.beneficios?.map((item, index) => (
              <div key={index}>
                <StatusBeneficios item={item} />
              </div>
            ))}
            <Row>
              {valoresForm?.mensagens?.map((item, index) => (
                <Alert
                  key={index}
                  message={statusAlert[Object.keys(item)[0]].titulo}
                  description={Parser(item[Object.keys(item)[0]])}
                  type={statusAlert[Object.keys(item)[0]].tipo}
                  showIcon
                />
              ))}
            </Row>
            <Row
              type="flex"
              justify="end"
              hidden={ocultarEtapa2 || !ocultarEtapa3}
            >
              <Col>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setOcultarEtapa3(false);
                    obterCPF(form.getFieldValue(["pessoa", "cpf"]));
                    valoresForm["mensagens"] = [];
                  }}
                  hidden={!valoresForm?.permiteAntecipar}
                >
                  <i className="fa fa-user-check" />
                  Antecipar Recadastramento {valoresForm?.referenciaAntecipar}
                </button>
              </Col>
              <Col>
                <button
                  type="button"
                  className="btn btn-outline-success mr-2"
                  onClick={() => setMostrarModalHistorico(true)}
                >
                  <i className="fa fa-search" />
                  Histórico
                </button>
              </Col>
              <Col>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => setOcultarEtapa3(false)}
                  hidden={!valoresForm?.permiteRealizar}
                >
                  <i className="fa fa-user-check" />
                  Recadastramento {valoresForm?.referencia}
                </button>
              </Col>
            </Row>
          </Painel>
          <div hidden={ocultarEtapa3}>
            <Painel subTitulo="Representante Legal">
              <div hidden={valoresForm?.representanteLegal}>
                <label style={{ display: "block", fontSize: "16px" }}>
                  O Benefíciário está acompanhando de um representante Legal, de
                  acordo com o Art.19, Lc 77/2010?
                </label>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => setMostrarModalRepresentante(true)}
                >
                  <i className="fa fa-user-shield" />
                  Cadastrar Representante Legal
                </button>
                {validacaoRepresentante() && exibeMensagemRepresentante && (
                  <span className="campoObrigatorio">
                    Representante é Obrigatório
                  </span>
                )}
              </div>
              <div
                className="caixa-flex"
                hidden={!valoresForm?.representanteLegal}
                onClick={() => setMostrarModalRepresentante(true)}
              >
                <button
                  type="button"
                  className="btn btn-icon-info"
                  title="Mostrar dados Representante Legal"
                >
                  <i className="fa fa-search" />
                </button>
                <span className="representante ml-3">{`${applyMaskCpf(
                  valoresForm?.representanteLegal?.pessoa?.cpf
                )} - ${valoresForm?.representanteLegal?.pessoa?.nome}`}</span>
              </div>
            </Painel>
          </div>
          <div hidden={ocultarEtapa3}>
            <Painel subTitulo="Endereço">
              <Endereco nome="endereco" form={form} status={controleEtapa2} />
              <Contato nome="contato" />
              <Row type="flex" justify="end">
                <Col>
                  <button
                    type="button"
                    className="btn btn-outline-dark mr-2"
                    onClick={cancelar}
                  >
                    <i className="fa fa-times" />
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-info">
                    <i className="fa fa-user-check" />
                    Finalizar Recadastramento
                  </button>
                </Col>
              </Row>
            </Painel>
          </div>
        </Form>
        {mostrarModalHistorico && (
          <ModalHistorico
            show
            close={() => setMostrarModalHistorico(false)}
            onRetificar={(id) => {
              setMostrarModalHistorico(false);
              setOcultarEtapa3(false);
              valoresForm["mensagens"] = [];
            }}
            cpf={form.getFieldValue(["pessoa", "cpf"])}
            nome={form.getFieldValue(["pessoa", "nome"])}
          />
        )}
        {mostrarModalRepresentante && (
          <ModalRepresentante
            show
            close={() => setMostrarModalRepresentante(false)}
            valores={valoresForm?.representanteLegal}
            noFinish={noFinishModalRepresentante}
          />
        )}
      </Secured>
    </>
  );
};

export default Recadastramento;
