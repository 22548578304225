const dev = {
  //BASE_API: "https://censo-api-homo.goiasprev.go.gov.br/",
  BASE_API: "http://localhost:8080/",
  KEYCLOAK_AUTH: "https://autenticador-homo.goiasprev.go.gov.br/auth/",
  ENDERECO_API: "https://endereco-api.goiasprev.go.gov.br/",
  SA_API: "https://sa-api-homo.goiasprev.go.gov.br/",
  AUDITORIA: "https://auditor-api-homo.servicos.goiasprev.go.gov.br/",
  GPREV_API: "https://gprev-api-homo.goiasprev.go.gov.br/",
  AUDITORIA_API: "https://auditor-api-homo.goiasprev.go.gov.br/",
};

const homo = {
  BASE_API: "https://censo-api-homo.goiasprev.go.gov.br/",
  KEYCLOAK_AUTH: "https://autenticador-homo.goiasprev.go.gov.br/auth/",
  ENDERECO_API: "https://endereco-api.goiasprev.go.gov.br/",
  SA_API: "https://sa-api-homo.goiasprev.go.gov.br/",
  AUDITORIA: "https://auditor-api-homo.goiasprev.go.gov.br/",
  GPREV_API: "https://gprev-api-homo.goiasprev.go.gov.br/",
  AUDITORIA_API: "https://auditor-api-homo.goiasprev.go.gov.br/",
};

const prod = {
  BASE_API: "https://censo-api.goiasprev.go.gov.br/",
  KEYCLOAK_AUTH: "https://autenticador.goiasprev.go.gov.br/auth/",
  ENDERECO_API: "https://endereco-api.goiasprev.go.gov.br/",
  SA_API: "https://sa.goiasprev.go.gov.br/",
  AUDITORIA: "https://auditor-api.goiasprev.go.gov.br/",
  GPREV_API: "https://gprev-api.goiasprev.go.gov.br/",
  AUDITORIA_API: "https://auditor-api.goiasprev.go.gov.br/",
};

const config = () => {
  if (process.env.REACT_APP_ENV === "production") return prod;
  if (process.env.REACT_APP_ENV === "homolog") return homo;
  return dev;
};

export default {
  MAX_ATTACHMENT_SIZE: 500000,
  ...config(),
};
