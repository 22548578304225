import React from 'react';
import { Fragment } from 'react';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';
import './styles.scss';

const Erro404 = () => {

    return (
        <Fragment>
            <Painel classPainel='row justify-content-md-center painel-erro' classConteudo='col-lg-3'>
                <div className='row justify-content-md-center text-center conteudo'>
                    <i className='fas fa-exclamation-circle fa-5x'/>
                    <div className='col-md-12 mt-4'>
                        <span className='title-erro'>Erro 404</span>
                    </div>
                    <p className='col-md-12'>A página que você estava tentando acessar não existe ou não está disponível</p>
                </div>
            </Painel>
        </Fragment>
    )
}

export default Erro404;