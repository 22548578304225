import React , {useReducer} from "react";
import MenuContext from "./Context";
import menuReducer from "./reducer"

const Provider = ({ children }) => {
    const [menu, menuDispatch] = useReducer(menuReducer);
    return (
        <MenuContext.Provider value={{ menu, menuDispatch}}>
            {children}
        </MenuContext.Provider>
    );
};

export default Provider;