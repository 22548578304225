import React from "react";

import { Col, Form, Modal, Row, Select, Button } from "antd";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Endereco, Contato, IdentificacaoPessoa } from "./Componentes";
import SelectParentesco from "../../../component/Select/SelectParentesco";
import InputUpload from "./Input-upload";
import services from "../services";
import { isObrigatorioArquivoComRegEXP } from "../../../helper/validation";

const TIPO_REPRESENTANTE = [
    {value: "GUARDIAO", label:"Guardião"},
    {value: "CURADOR", label:"Curador"},
    {value: "ADMINISTRADOR_PROVISORIO", label:"Administrador Provisório"}
];

const ModalRepresentante = ({
    show,
    close,
    valores,
    noFinish
}) => {

    const [form] = Form.useForm();
    const [controleEtapa2, setControleEtapa2] = React.useState(false);
    const [arquivoObrigatorio, setArquivoObrigatorio] = React.useState(false);
    const [parametroObrigatorio, setParametroObrigatorio] = React.useState("");

    React.useEffect(()=>{
        validarArquivoRegExp();
        setControleEtapa2(true);
    }, []);

    React.useEffect(()=>{
        if(valores?.tipo) verificarObrigatoriedade(valores.tipo);
        form.setFieldsValue(valores);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, valores]);

    const handlePesquisarCPF = (valor)=>{
        form.setFieldsValue({...valores, pessoa: valor});
        setControleEtapa2(!controleEtapa2);
    }

    const validarArquivoRegExp = ()=>{
        services.getParametros()
            .then(response=>response.data.RECADASTRAMENTO__REQUIRED_REPRESENTANTE)
            .then(setParametroObrigatorio);
    }
    const verificarObrigatoriedade = (value) => {
        setArquivoObrigatorio(isObrigatorioArquivoComRegEXP(parametroObrigatorio, value));
        return value;
    }

    const botoesModal = [!valores ? <Button key="back" type="primary" ghost onClick={close} icon={<CloseOutlined />}>
                            Cancelar
                        </Button> : <Button key="back" type="primary" danger onClick={()=> noFinish(null)} icon={<DeleteOutlined />}>
                            Excluir
                        </Button>,
                        <Button key="submit" type="primary" onClick={form.submit} icon={<PlusOutlined />}>
                            Salvar
                        </Button>];
    return (<Modal title="Cadastrar Representante Legal" visible={show} onCancel={close} onOk={form.submit} centered width={1300} footer={botoesModal}>
                <Form 
                    form={form}
                    onFinish={noFinish}>
                    <IdentificacaoPessoa nome="pessoa" camposAdicionais={false} handleValoresCPF={handlePesquisarCPF} pesquisarRepresentante desabilitarCampos={false}/>
                    <Endereco nome="pessoa" form={form} status={controleEtapa2}/>
                    <Contato nome="pessoa" ocultarTitulo={true}/>
                    <Row>
                        <Col span={6}>
                            <Form.Item name="tipo" label="Tipo de Representante" rules={[{required:true}]} getValueFromEvent={verificarObrigatoriedade}>
                                <Select options={TIPO_REPRESENTANTE}/>
                            </Form.Item>
                        </Col>
                        
                        <Form.Item noStyle shouldUpdate={(prev,next) => prev.tipo !== next.tipo}>
                        {({getFieldValue}) => {
                            const tipoRepresentante = getFieldValue('tipo');
                            return tipoRepresentante !== "ADMINISTRADOR_PROVISORIO" ? null : 
                            <Col span={8}>
                                <Form.Item name="parentesco" label="Parentesco">
                                    <SelectParentesco showSearch/>
                                </Form.Item>
                            </Col> 
                        }}
                        </Form.Item>
                        <Col span={10}>
                            <Form.Item name="arquivo" label="Comprovante Representante Legal" valuePropName="fileList" getValueFromEvent={InputUpload.normFile} rules={[{required: arquivoObrigatorio}]}>
                                <InputUpload label="Selecione um arquivo PDF" accept=".pdf" valor={form.getFieldValue("arquivo")}/>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                </Form>
            </Modal>);
}

export default ModalRepresentante;