/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Permissions } from "../../Constantes/permissions.constants";
import { Card, Col, Form, Row, Button, Switch, notification } from "antd";
import { Secured } from "../../component";
import {
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  ArrowLeftOutlined,
  SaveFilled,
} from "@ant-design/icons";
import InputCPF from "../../component/InputCpf";
import services from "./services";
import "./style.scss";

const AdministracaoUsuario = () => {
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const [cpf, setCPF] = useState();
  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [disableButtonSave, setDisableButtonSave] = useState(true);
  const [showSearch, setShowSearch] = useState(true);

  const handleSearch = (cpf) => {
    cpf = cpf.replace(/\D/g, "");

    try {
      setLoading(true);
      setCardLoading(true);

      services.getUserPermissions(cpf).then((resp) => {
        setData(resp.data);
        setCPF(cpf);
        setShowSearch(false);
      });
    } finally {
      setLoading(false);
      setCardLoading(false);
    }
  };

  const hasPermission = (id) => {
    return data?.gruposUsuario.find((group) => group.id === id) != undefined;
  };

  const onChangePermission = (switchValue, perm, index) => {
    setDisableButtonSave(false);
    if (switchValue === false) {
      let chaveItem = -1;
      data?.gruposUsuario.forEach((item, key) => {
        if (chaveItem < 0)
          item.id === perm.id ? (chaveItem = key) : (chaveItem = -1);
      });
      if (chaveItem > -1) data?.gruposUsuario.splice(chaveItem, 1);
    } else if (switchValue === true) {
      data?.gruposUsuario.push(perm);
    }
  };

  const handleSubmit = () => {
    if (data) {
      data.username = cpf;
      data.sistema = "Censo";

      try {
        setLoading(true);
        services.putUserPermissions(data).then(() => {
          notification.success({
            message: "Sucesso!",
            description: `As Permissões foram salvas!`,
            duration: 5,
          });
        });
      } finally {
        setLoading(false);
        setDisableButtonSave(true);
      }
    }
  };

  return (
    <>
      <Secured hasAnyRole={Permissions.USUARIO.TELA} isShowErro403={true}>
        <PageContainer>
          <h1>Administração do Usuário</h1>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Row>
              <Col span={24}>
                <Card>
                  <Col span={24}>
                    <Form.Item
                      name={"cpf"}
                      label="CPF:"
                      rules={[{ validator: InputCPF.Validator }]}
                    >
                      <InputCPF
                        placeholder="Digite o N° do Cpf"
                        showSearch={showSearch}
                        onSearch={(cpf) => handleSearch(cpf)}
                        readOnly={false}
                        disabled={false}
                        style={{ width: "200px" }}
                        name={"cpf"}
                        loading={cardLoading}
                        onChange={() => {
                          setShowSearch(true);
                          setData(undefined);
                          setDisableButtonSave(true);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Card>

                {data && (
                  <Card loading={cardLoading}>
                    <Row>
                      <Col sm={24} md={24} lg={10}>
                        <h2>Informações do Usuário</h2>
                        <label>Nome</label>
                        <div className={"box"}>
                          <UserOutlined style={{ fontSize: "25px" }} />
                          <h3 className={"dataContent"}>{data?.nome}</h3>
                        </div>

                        <label>Email</label>
                        <div className={"box"}>
                          <MailOutlined
                            style={{ fontSize: "25px", marginTop: "4px" }}
                          />
                          <h3 className={"dataContent"}>{data?.email}</h3>
                        </div>

                        <label>Localidade</label>
                        <div className={"box"}>
                          <EnvironmentOutlined style={{ fontSize: "25px" }} />
                          <h3 className={"dataContent"}>{data?.nomeCidade}</h3>
                        </div>
                      </Col>

                      <Col sm={0} md={0} lg={6}></Col>

                      <Col
                        sm={24}
                        md={24}
                        lg={8}
                        style={{ marginBottom: "50px" }}
                      >
                        <div>
                          <h2>Permissões</h2>
                          {data?.grupos.map((permission, i) => (
                            <>
                              <Row>
                                <Col
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label>{permission?.name}</label>
                                  <Switch
                                    onChange={(e) =>
                                      onChangePermission(e, permission, i)
                                    }
                                    id={permission.id}
                                    defaultChecked={hasPermission(
                                      permission.id
                                    )}
                                    size="small"
                                    style={{ width: "30px" }}
                                  />
                                </Col>
                              </Row>
                            </>
                          ))}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          height: "50px",
                        }}
                      >
                        <Button
                          icon={<ArrowLeftOutlined />}
                          style={{ marginRight: 5 }}
                          onClick={() => {
                            form.resetFields();
                            setData(undefined);
                          }}
                        >
                          Voltar
                        </Button>

                        <Button
                          type="primary"
                          htmlType="submit"
                          icon={<SaveFilled />}
                          loading={loading}
                          disabled={disableButtonSave}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
            </Row>
          </Form>
        </PageContainer>
      </Secured>
    </>
  );
};

export default AdministracaoUsuario;
