import Keycloak from 'keycloak-js';
import React from 'react';
import LoginRequired from './LoginRequired';
import { SecurityContext } from './SecurityContext';
import useKeycloak from './useKeycloak';

const SecurityProvider = ({
  config,
  children,
  loginRequired,
  onLoginSuccess,
  validateUser,
  renderInvalidUser,
}) => {
  const keycloak = new Keycloak(config);
  const securityEngine = useKeycloak(keycloak);
  const loginProps = { onLoginSuccess, validateUser, renderInvalidUser };

  const [context, updateContext] = React.useState({ ...securityEngine });

  React.useEffect(() => {
    securityEngine.onRefreshToken(() => {
      const tokenRefreshedAt = new Date();
      updateContext({ ...context, tokenRefreshedAt});
    });
  }, [context, securityEngine]);

  return (
    <SecurityContext.Provider value={{ ...context }}>
      {
        loginRequired ? (
          <LoginRequired {...loginProps}>{children}</LoginRequired>
        ) :
          (
            children
          )
      }
    </SecurityContext.Provider>
  );
};

export default SecurityProvider;