import axios from "axios";
import config from "../../config";

const SA_API = config.SA_API;

const getUserPermissions = (cpf) =>
  axios.get(`${SA_API}keycloak/usuario/${cpf}?sistema=Censo`);

const putUserPermissions = (data) =>
  axios.put(`${SA_API}keycloak/`, {
    ativo: data.ativo,
    clientRoles: data.clientRoles,
    email: data.email,
    firstName: data.firstName,
    grupos: data.grupos,
    gruposUsuario: data.gruposUsuario,
    id: data.id,
    idCidade: data.idCidade,
    lastName: data.lastName,
    nome: data.nome,
    nomeCidade: data.nomeCidade,
    sistema: data.sistema,
    username: data.username,
    usuario: data.usuario,
    usuarioLdap: data.usuarioLdap,
  });

export default {
  getUserPermissions,
  putUserPermissions,
};
