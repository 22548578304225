import axios from 'axios'
import config from '../../config';

const BASE_API = config.BASE_API;

const obterCalendario = (id) => axios.get(`${BASE_API}calendario/${id}`);

const obterListaCalendario = () => axios.get(`${BASE_API}calendario`);

const salvarCalendario = (calendario) => axios.post(`${BASE_API}calendario`, calendario);

const alterarCalendario = (calendario) => axios.put(`${BASE_API}calendario`, calendario);

const excluirCalendario = (id) => axios.delete(`${BASE_API}calendario/${id}`);

export default {
    obterCalendario,
    obterListaCalendario,
    salvarCalendario,
    alterarCalendario,
    excluirCalendario
}