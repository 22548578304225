import React, { useState } from "react";
import "../style.scss";
import Painel from "gp-painel";
import { Secured } from "../../../component";
import { Permissions } from "../../../Constantes/permissions.constants";
import { Link } from "react-router-dom";
import {
  Form,
  DatePicker,
  Button,
  Row,
  Col,
  Switch,
  Input,
  message,
  Radio,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ItemPeriodo from "../Componentes/Item-periodo";
import { useParams } from "react-router";
import services from "../services";
import { formatarData } from "../../../helper/util";
import moment from "moment";
import periodosIniciais from "../Constantes/PeriodosInicias";
import { Message } from "../../../Constantes/message.constants";
import { useHistory } from "react-router-dom";
import { useCalendarioRecadastramento } from "../../../state/CalendarioRecadastramento/CalendarioRecadastramentoProvider";

const converterMesData = (month) =>
  month != null
    ? moment().set({
        year: new Date().getFullYear(),
        month: month - 1,
        date: 1,
      })
    : null;

const converterDataMes = (date) => date.month() + 1;

const CalendarioNovo = () => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [valores, setValores] = React.useState(null);
  const [desabilitaPeriodoSuspender, setDesabilitaPeriodoSuspender] =
    React.useState(false);
  const { calendariosCadastrados } = useCalendarioRecadastramento();
  const [regime, setRegime] = useState("AMBOS");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    form.setFieldsValue(valores);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valores]);

  React.useEffect(() => {
    obterCalendario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeRegime = (e) => {
    setRegime(e.target.value);
  };

  const processarPeriodos = (periodos, dataEmMes = false) => {
    return periodos != null
      ? periodos.map((item) => {
          return {
            ...item,
            mesAniversario: dataEmMes
              ? converterDataMes(item.mesAniversario)
              : converterMesData(item.mesAniversario),
            mesRecadastramentoIni: dataEmMes
              ? converterDataMes(item.mesRecadastramentoIni)
              : converterMesData(item.mesRecadastramentoIni),
            mesRecadastramentoFim: dataEmMes
              ? converterDataMes(item.mesRecadastramentoFim)
              : converterMesData(item.mesRecadastramentoFim),
          };
        })
      : [];
  };

  const obterCalendario = () => {
    if (id !== undefined) {
      setLoading(true);
      services
        .obterCalendario(id)
        .then((resultado) => resultado.data)
        .then((data) => {
          data.dataInicioVigencia = formatarData(data.dataInicioVigencia);
          data.periodos = processarPeriodos(data.periodos);
          setRegime(data.regime);
          return data;
        })
        .then(setValores)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else
      setValores({ periodos: processarPeriodos(periodosIniciais.periodos) });
  };

  const finalizarCalendario = (dados) => {
    setLoading(true);
    dados.periodos = processarPeriodos(dados.periodos, true);
    id === null
      ? services.salvarCalendario(dados).then(retornoProcessamento)
      : services.alterarCalendario(dados).then(retornoProcessamento);
    setLoading(false);
  };

  const retornoProcessamento = () => {
    message.success(
      Message.MSG018_GERAL_SALVO_COM_SUCESSO.replace("{0}", "Calendário"),
      3
    );

    history.push("/calendario");
  };

  return (
    <Secured
      hasAnyRole={Permissions.CALENDARIOSCENSO.TELA}
      isShowErro403={true}
    >
      <Form
        form={form}
        name="dynamic_form_item"
        onFinish={finalizarCalendario}
        layout="vertical"
        scrollToFirstError={true}
      >
        <Painel titulo="Calendário Recadastramento Censo">
          <Form.Item name="id" hidden rules={[{ required: id != null }]}>
            <Input />
          </Form.Item>
          <Row>
            <Col span={16}>
              <Form.Item
                name="descricao"
                label="Descrição"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="dataInicioVigencia"
                label="Data início vigência"
                dependencies={["regime"]}
                rules={[
                  {
                    required: true,
                    message: "Por Favor, insira uma data.",
                  },
                  ({ getFieldValue }) => ({
                    async validator(_, value) {
                      calendariosCadastrados.map((calendario) => {
                        const calendarioDigitadoFormatado =
                          value?.format("YYYY-MM-DD");

                        if (
                          (calendarioDigitadoFormatado ===
                            calendario.dataInicioVigencia &&
                            getFieldValue("regime") === calendario.regime) ||
                          (calendarioDigitadoFormatado ===
                            calendario.dataInicioVigencia &&
                            calendario.regime === "ambos")
                        ) {
                          if (calendario.regime === "ambos") {
                            throw new Error(
                              'Essa data de início já esta cadastrada para "ambos" os regimes'
                            );
                          } else
                            throw new Error(
                              `Essa data de início já esta cadastrada para o regime ${getFieldValue(
                                "regime"
                              )}`
                            );
                        }

                        return value;
                      });
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  disabledDate={(date) => date.isSame("2022-01-01")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item
                name="bloquear"
                label="Bloqueiar/Suspender"
                valuePropName="checked"
                initialValue={true}
                rules={[{ required: true }]}
              >
                <Switch
                  onChange={() => {
                    setDesabilitaPeriodoSuspender(
                      !form.getFieldValue("bloquear")
                    );
                    form.resetFields(["suspenderAposMeses"]);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="suspenderAposMeses"
                label="Suspender Apos(Meses)"
                rules={[{ required: !desabilitaPeriodoSuspender }]}
              >
                <Input type="number" disabled={desabilitaPeriodoSuspender} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"regime"}
                rules={[
                  { required: true, message: "Por favor, selecione um regime" },
                ]}
                label="Regimes"
                initialValue={regime}
              >
                <Radio.Group onChange={onChangeRegime} value={regime}>
                  <Radio className="span-radio" value="AMBOS">
                    Ambos
                  </Radio>
                  <Radio className="span-radio" value="CIVIL">
                    Civil
                  </Radio>
                  <Radio className="span-radio" value="MILITAR">
                    Militar
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Painel>
        <Painel subTitulo="Periodos">
          <Row>
            <Col span={8}>Mês de Aniversário</Col>
            <Col span={8}>Mês Início</Col>
            <Col span={8}>Mês Fim</Col>
          </Row>
          <Form.List name="periodos">
            {(fields) => (
              <div className="content-list">
                {fields.map((field) => (
                  <ItemPeriodo {...field}></ItemPeriodo>
                ))}
              </div>
            )}
          </Form.List>
        </Painel>
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              height: "50px",
            }}
          >
            <Link to="/calendario">
              <Button
                className="button-space"
                disabled={loading}
                style={{ marginRight: 10 }}
              >
                Voltar
              </Button>
            </Link>

            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              htmlType="submit"
              icon={<PlusOutlined />}
            >
              Salvar Calendário
            </Button>
          </Col>
        </Row>
      </Form>
    </Secured>
  );
};

export default CalendarioNovo;
