const periodosIniciais = {"periodos":[
        {
            "mesAniversario": 1,"mesRecadastramentoIni": 1,"mesRecadastramentoFim":2
        },
        {
            "mesAniversario": 2,"mesRecadastramentoIni":2,"mesRecadastramentoFim":3
        },
        {
            "mesAniversario": 3,"mesRecadastramentoIni":3,"mesRecadastramentoFim":4
        },
        {
            "mesAniversario": 4,"mesRecadastramentoIni":4,"mesRecadastramentoFim":5
        },
        {
            "mesAniversario": 5,"mesRecadastramentoIni":5,"mesRecadastramentoFim":6
        },
        {
            "mesAniversario": 6,"mesRecadastramentoIni":6,"mesRecadastramentoFim":7
        },
        {
            "mesAniversario": 7,"mesRecadastramentoIni":7,"mesRecadastramentoFim":8
        },
        {
            "mesAniversario": 8,"mesRecadastramentoIni":8,"mesRecadastramentoFim":9
        },
        {
            "mesAniversario": 9,"mesRecadastramentoIni":9,"mesRecadastramentoFim":10
        },
        {
            "mesAniversario": 10,"mesRecadastramentoIni":10,"mesRecadastramentoFim":11
        },
        {
            "mesAniversario": 11,"mesRecadastramentoIni":11,"mesRecadastramentoFim":12
        },{
            "mesAniversario": 12,"mesRecadastramentoIni":12,"mesRecadastramentoFim":1
        }
    ]};

export default periodosIniciais;