const useKeycloak = (keycloak) => {
  const init = (options) => keycloak.init(options);

  const logout = () => keycloak.logout();

  const isAuthenticated = () => keycloak.authenticated;

  const getToken = () => keycloak.token;

  const currentUser = () => keycloak.tokenParsed;

  const getTokenParsed = () => keycloak.tokenParsed;

  const hasResourceRole = (role) =>
    keycloak.hasResourceRole(role) || keycloak.hasRealmRole(role);

  const hasPermission = ({ hasRole, hasAnyRole, hasAllRole }) => {
    return (hasRole && hasResourceRole(hasRole)) ||
      (hasAnyRole && hasAnyRole.map(hasResourceRole).find((passed) => passed === true)) ||
      (hasAllRole && hasAllRole.every(hasResourceRole));
  }

  const onRefreshToken = (callback) => {
    keycloak.onTokenExpired = () => keycloak.updateToken(0).then(callback).catch(console.log);
  }
    


  return {
    init,
    logout,
    getToken,
    currentUser,
    getTokenParsed,
    isAuthenticated,
    hasResourceRole,
    hasPermission,
    onRefreshToken,
  };
};

export default useKeycloak;