export const Message = {
  MSG000_REALIZADA_COM_SUCESSO: "Operação realizada com sucesso !",
  MSG001_CPF_NAO_ENCONTRADO: "CPF não encontrado",
  MSG002_CPF_INVALIDO: "Informe um CPF válido.",
  MSG003_CPF_NAO_ENCONTRADO: "CPF Informado não encontrado na base de dados.",
  MSG004_EXCLUIR_REGISTRO:
    "Tem certeza que deseja excluir/inativar o registro?",
  MSG005_RECADASTRO_SUCESSO: "Recadastro realizado com sucesso!",
  MSG006_RETIFICACAO_SUCESSO: "Retificação realizada com sucesso!",
  MSG007_BENEFICIARIO_BLOQUEADO:
    "Beneficiário bloqueado a 6 ou mais meses. O recadastramento deve ser feito na sede da Goiasprev.",
  MSG008_RUBRICA_INSERIDA:
    'A rubrica inserida não está no cálculo, para inserir o valor clique no botão "Recalcular".',
  MSG009_POSSIVEL_EXCLUIR:
    "Somente é possível editar/excluir o último registro que tenha data de inclusão inferior a X[data predeterminada] dias.",
  MSG010_PROCESSO_JA_INSERIDO:
    "Este número de processo já foi inserido pelo CPF X[numero do cpf que o número possuí vínculo].",
  MSG011_INCONSISTENCIA:
    "Há inconsistência(s) nos seus dados por concomitância de averbações. Por favor, entre em contato com o órgão.",
  MSG013_ALTERAR_REGISTRO: "Tem certeza que deseja alterar o registro?",
  MSG014_ARQUIVO_IMPORTADO_SUCESSO: "Arquivo Sisobit importado com sucesso!",
  MSG016_ALTERAR_RECADASTRAMENTO:
    "Tem certeza que deseja realizar o recadastramento de {0} para o ano {1}?",
  MSG017_CPF_SEGURADO_IGUAL_REPRESENTANTE:
    "CPF inserido não pode ser o mesmo que o do Beneficiário!",
  MSG018_GERAL_SALVO_COM_SUCESSO: "{0} salvo com sucesso!",
  MSG018_GERAL_EXCLUIDO_COM_SUCESSO: "{0} excluído com sucesso!",

  CAMPO_OBRIGATORIO: "Campo obrigatório",
  CEP_NAO_ENCONTRADO: "CEP não encontrado",
  TITLE_CONFIRMACAO: "Confirmação",
  EMAIL_INVALIDO: "E-Mail inválido",
};

export const Situations = {
  APROVADO: "Aprovado pelo Analista",
  CONCLUIDO: "Concluído",
  EM_ANALISE: "Em Análise",
  EXTINGUIDO: " Extinguido pelo Analista",
  INICIADO: "Iniciado",
  REPROVADO: "Reprovado pelo Analista",
  RETIFICADO: "Retificado",
};
