import React from "react";

import { Select } from "antd";
import services from "../../page/Recadastramento/services";

function SelectParentesco(props) {
  const [opcoes, setOpcoes] = React.useState([]);

  React.useEffect(() => {
    services
      .getOpcoes()
      .then(res => res.data)
      .then(data => data.tipoParentesco)
      .then(setOpcoes);
  }, []);

  return (
    <Select {...props} options={opcoes}/>
  );
}

export default SelectParentesco;
