import * as MainHomeTypes from "./types"

const reducer = (state, action) => {
    switch (action.type) {
        case MainHomeTypes.ADD_MAIN_HOME:
            return action.payload.value;
        case MainHomeTypes.REMOVE_MAIN_HOME:
            return null;
        default:
            throw new Error();
    }
};

export default reducer;