import * as Menutypes from "./types";

export const pinned = {
    type: Menutypes.PINNED,
    payload: {value: true}
};

export const unpined = {
    type: Menutypes.UNPINED,
    payload: {value: false}
}

export const changePinned = value => ({
    type: value ? Menutypes.PINNED : Menutypes.UNPINED,
    payload: {value: value}
});