import * as MenuTypes from "./types"

const reducer = (state, action) => {
    switch (action.type) {
        case MenuTypes.PINNED:
            return true;
        case MenuTypes.UNPINED:
            return false;
        default:
            throw new Error();
    }
};

export default reducer;