import React, { useEffect, useState } from "react";
import Modal from "gp-modal";
import 'gp-modal/dist/index.css';
import Services from './services';

const ModalHistorico = ({show, close, cpf, nome, onRetificar}) => {

    const [historico, setHistorico] = useState([]);

    const visualizar = (e, item) => {
        return Services.visualizar(item.id);
    }

    useEffect(() => {
        if (show) {
            Services.obterHistorico(cpf)
                .then(response => response.data)
                .then(setHistorico)
                .catch(e => console.log);
        }
    }, [show]);

    return (
        <> 
            <Modal title={`Histórico de Recadastramento de ${nome}`} showModal={show} closeModal={close} classeSize={'modal-xl'}>
                <div className="modal-body">
                <table className="table table-sm table-bordered table-align table-striped">
                    <thead className="thead-light">
                        <tr>
                            <th className="text-center">Referência</th>
                            <th className="text-center">Data de Recadastro</th>
                            <th className="text-center">Situação</th>
                            <th className="text-center" width="10%">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historico.length > 0 ? historico.map((item, index) => (
                            <tr key={index}>
                                <td className='text-center'>{item.descricao}</td>
                                <td className='text-center'>{item.data}</td>
                                <td className='text-center'>{item.situacao}</td>
                                <td className='text-left' >
                                    {item.permiteEmitirComprovante && (
                                      <button
                                        type="button"
                                        onClick={(event) => visualizar(event, item)}
                                        title='Visualizar'
                                        className="btn btn-outline-success btn-sm ml-2" >
                                          <i className="fa fa-file-alt m-0"></i>
                                      </button>                            
                                    )}
                                    {item.permiteRetificar && (
                                      <button
                                        type="button"
                                        onClick={()=>onRetificar(item.id)}
                                        title='Retificar'
                                        className="btn btn-outline-success btn-sm btn-sm ml-2" >
                                          <i className="fa fa-edit m-0"></i>
                                      </button>                                    
                                    )}
                                </td>
                            </tr>
                        ))
                        : <tr><td colSpan='4' className="noResult text-center"> Nenhum registro encontrado</td></tr>
                    }
                    </tbody>
                </table>
                </div>
                <div className="modal-footer p-3">
                    <button type="button" className="btn btn-outline-dark" onClick={close}>
                            <i className='fas fa-times' />
                        Voltar
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ModalHistorico;