import React from 'react';
import { useSecurityContext } from './useSecurityContext';

const LoginRequired = ({
  children,
  onLoginSuccess,
  renderLoading,
  validateUser,
  renderInvalidUser,
}) => {
  const [success, setSuccess] = React.useState();
  const [invalidUser, setInvalidUser] = React.useState();
  const { init, currentUser, getToken, config } = useSecurityContext();

  React.useEffect(() => {
    if (success && onLoginSuccess) {
      onLoginSuccess(success);
    }
  }, [onLoginSuccess, success]);

  React.useEffect(() => {
    init({ onLoad: 'login-required', promiseType: 'native', checkLoginIframe: false, enableLogging: true, ...config }).then(
      () => {
        const token = getToken();
        const user = currentUser();
        const loginInfo = { user, token };
        if (validateUser) {
          validateUser(loginInfo)
            .then(setSuccess)
            .catch((error) => setInvalidUser({ error, loginInfo }));
        } else {
          setSuccess(loginInfo);
        }
      }
    );
  }, [config, currentUser, getToken, init, validateUser]);

  return success
    ? React.cloneElement(children, success)
    : invalidUser
      ? renderInvalidUser(invalidUser)
      : renderLoading;
};

LoginRequired.defaultProps = {
  renderLoading: null,
  onLoginSuccess: () => { },
};

export default LoginRequired;