import React, { useEffect } from "react";
import { Secured } from "../../../component";
import { Permissions } from "../../../Constantes/permissions.constants";
import moment from "moment";
import { MailFilled } from "@ant-design/icons";
import "../style.less";
import { Form, Col, Card, Row, Button, DatePicker, notification } from "antd";
import services from "../services";
import { formatDate } from "../../../helper/util";

const Desbloqueio = () => {
  const [form] = Form.useForm();

  const initialValuesSchema = {
    dataInicial: moment(),
    dataFinal: moment(),
  };

  useEffect(() => {
    form.setFieldsValue(initialValuesSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, initialValuesSchema]);

  const sendEmail = (data) => {
    form.validateFields(["dataInicial", "dataFinal"]).then(() => {
      console.log("OQUE VENHO", data);

      data.dataInicial = formatDate(data.dataInicial, "YYYY-MM-DD");
      data.dataFinal = formatDate(data.dataFinal, "YYYY-MM-DD");

      services
        .getReport(data)
        .then((resp) => {
          notification.success({
            message: "Sucesso!",
            description: `Relatório de Desbloqueio enviado.`,
            duration: 10,
          });
        })
        .finally(() => {});
    });
  };

  return (
    <>
      <Secured
        hasAnyRole={Permissions.RECADASTRAMENTO.TELA}
        isShowErro403={true}
      >
        <Form layout="vertical" form={form} onFinish={sendEmail}>
          <Card title="Relatório de Desbloqueio">
            <Row>
              <Col sm={24} md={10} lg={7}>
                <Form.Item
                  label="De"
                  name="dataInicial"
                  dependencies={["dataFinal"]}
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a Data!",
                    },
                    ({ getFieldValue }) => ({
                      async validator(_, value) {
                        const endDate = getFieldValue("dataFinal");
                        const startDate = getFieldValue("dataInicial");
                        if (value && startDate > endDate) {
                          return Promise.reject(
                            new Error("Data Inicial e maior que Data Final!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ height: "42px", width: "100%" }}
                    disabledDate={(d) => d.isAfter(moment(new Date()))}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={10} lg={7}>
                <Form.Item
                  label="Até:"
                  name="dataFinal"
                  dependencies={["dataInicial"]}
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a Data!",
                    },
                    ({ getFieldValue }) => ({
                      async validator(_, value) {
                        const startDate = getFieldValue("dataInicial");
                        const endDate = getFieldValue("dataFinal");
                        if (
                          value &&
                          (startDate === undefined ||
                            startDate === "" ||
                            !startDate)
                        ) {
                        } else if (value && endDate < startDate) {
                          return Promise.reject(
                            new Error("Data Final e menor que Data Inicial!")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ height: "42px", width: "100%" }}
                    disabledDate={(d) => d.isAfter(moment(new Date()))}
                  />
                </Form.Item>
              </Col>

              <Col sm={24} md={24} lg={4} className="align-col-button2">
                <Button
                  type="primary"
                  icon={<MailFilled />}
                  htmlType={"submit"}
                  style={{ marginTop: 22 }}
                >
                  Enviar Relatório
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </Secured>
    </>
  );
};

export default Desbloqueio;
