import React, { useMemo, useState, useEffect } from "react";
import {
  Table,
  Card,
  Form,
  Select,
  DatePicker,
  Input,
  Tag,
  Button,
} from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import { QueryFilter } from "@ant-design/pro-form";
import "react-dual-listbox/lib/react-dual-listbox.css";
import services from "../services";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { Permissions } from "../../Constantes/permissions.constants";
import { formatDate, formatCpf } from "../../helper/util";
import { Secured } from "../../component";

const RelatorioGerencial = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState();
  // const [igualA, setIgualA] = useState(false);
  // const [maiorOuIgual, setMaiorOuIgual] = useState(false);
  // const [menorOuIgual, setMenorOuIgual] = useState(false);
  // const [entre, setEntre] = useState(false);
  const [recordLoadingButton, setRecordLoadingButton] = useState(false);
  const pagination = {
    page: 0,
    size: 10,
  };

  const months = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro",
  };

  const benefit = {
    1: "APOSENTADORIA",
    2: "PENSAO",
  };
  const status = {
    0: "Ativo",
    1: "Bloqueado",
    2: "Suspenso",
    3: "Cancelado",
    4: "Extinto",
  };

  const residencia = {
    1: "Dentro da UF",
    2: "Fora da UF",
  };

  const blockTypes = {
    1: "igual a",
    2: "maior ou igual a",
    3: "menor ou igual a",
    4: "entre",
  };

  const getReports = (parametros) => {
    setLoading(true);

    services
      .getReports(parametros)
      .then((resp) => {
        setData(resp?.data);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (filterValues) getReports(filterValues);
  }, [filterValues]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const pagination = {
      page: 0,
      size: 10,
    };
    getReports({
      pagination,
      searchQueries: [{}],
    });
  }, []);

  const onSearch = async (values) => {
    let searchQueries = [];
    if (values.valueIni !== undefined) {
      values.valueIni = formatDate(values.valueIni, "YYYY-MM-DD");
    }

    if (values.valueEnd !== undefined) {
      values.valueEnd = formatDate(values.valueEnd, "YYYY-MM-DD");
    }

    if (values.entreIni !== undefined) {
      values.entreIni = formatDate(values.entreIni, "YYYY-MM-DD");
    }
    if (values.entreEnd !== undefined) {
      values.entreEnd = formatDate(values.entreEnd, "YYYY-MM-DD");
    }

    for (const [key, value] of Object.entries(values)) {
      if (key === "valueIni") {
        searchQueries.push({
          field: "dataFinalVigencia",
          fieldType: "date",
          operator: "BT",
          valueEnd: values.valueEnd,
          valueIni: values.valueIni,
        });
      } else if (key === "status") {
        searchQueries.push({
          field: key,
          fieldType: "number",
          operator: "EQ",
          value: value,
        });
      } else if (key === "aposentadoria") {
        searchQueries.push({
          field: key,
          fieldType: "text",
          operator: "LK",
          value: value,
        });
      } else if (key === "censo.pessoa.uf") {
        searchQueries.push({
          field: key,
          value: value,
        });
      } else if (key === "mesNascimento") {
        searchQueries.push({
          field: key,
          value: value,
        });
      }
    }

    setFilterValues({
      pagination,
      searchQueries: searchQueries,
    });
  };

  const onChangeTable = function (pagination, filters, sorte) {
    var controlTable = {
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: "",
    };
    controlTable.sort =
      sorte.order != undefined
        ? sorte.columnKey + "," + sorte.order.substr(0, sorte.order.length - 3)
        : "";
    setFilterValues({
      ...filterValues,
      pagination: controlTable,
    });
  };

  const columns = useMemo(
    () => [
      {
        title: "Status do Recadastramento",
        dataIndex: "status",
        key: "status",
        width: "100px",
        sorter: true,
        render: (text, index) => (
          <Tag
            color={
              text == "Ativo" ? "green" : text == "Extinto" ? "gold" : "red"
            }
          >
            {text}
          </Tag>
        ),
      },
      {
        title: "CPF",
        dataIndex: "cpf",
        key: "cpf",
        render: (text) => formatCpf(text),
        width: "200px",
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "pessoa",
        width: "300px",
      },
      {
        title: "Data de Nascimento",
        dataIndex: "nascimento",
        key: "nascimento", //corrigir esta data

        width: "100px",
      },
      {
        title: "Tipo de Benefício",
        dataIndex: "tipo",
        sorter: true,
        key: "aposentadoria",
        render: (text) => text,
        width: "200px",
      },
    ],
    []
  );

  const imprimirConteudo = () => {
    document.getElementsByClassName("table-antd")[0].id = "id";
    let html = document.getElementById("id");
    var doc = new jsPDF("l", "pt", [800, 470]);
    if (html) {
      domtoimage.toPng(html).then((imgData) => {
        const imgProps = doc.getImageProperties(imgData);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        window.open(doc.output("bloburl"));
        setRecordLoadingButton(false);
      });
    }
  };

  const gerarRelatorio = (event) => {
    setRecordLoadingButton(true);
    imprimirConteudo("Relatório Gerencial", "imprimir");
  };

  return (
    <>
      <Secured hasAnyRole={Permissions.RELATORIOS.TELA} isShowErro403={true}>
        <PageContainer>
          <h1>Relatório Gerencial</h1>
          <Card
            title="Parâmetros do Relatório"
            style={{
              fontSize: "20px",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <QueryFilter
              onFinish={onSearch}
              layout="vertical"
              defaultCollapsed
              className="filtro"
            >
              <Form.Item label="Tipo de Benefício:" name="aposentadoria">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(benefit).map((value, index) => ({
                    label: benefit[value],
                    value: benefit[value],
                  }))}
                />
              </Form.Item>
              <Form.Item label="Status do recadastramento:" name="status">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(status).map((value, index) => ({
                    label: status[value],
                    value: index,
                  }))}
                />
              </Form.Item>

              <Form.Item label="Mês do Nascimento:" name="mesNascimento">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(months).map((value, index) => ({
                    label: months[value],
                    value: months[value],
                  }))}
                />
              </Form.Item>

              <Form.Item label="Residência:" name="censo.pessoa.uf">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(residencia).map((value, index) => ({
                    label: residencia[value],
                    value: index === 1 ? "" : "GO",
                  }))}
                />
              </Form.Item>

              <Form.Item
                label="Período Inicial:"
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.valueIni !== curValues.valueIni ||
                    prevValues.valueEnd !== curValues.valueEnd
                  );
                }}
              >
                {({ getFieldValue }) => {
                  const startDatePreenchido0 = getFieldValue("valueIni");

                  const endDatePreenchido0 = getFieldValue("valueEnd");

                  return (
                    <Form.Item
                      name="valueIni"
                      dependencies={["valueEnd"]}
                      rules={[
                        {
                          required: endDatePreenchido0 && true,
                          message: "Por Favor, insira data",
                        },
                        {
                          validator: async () => {
                            if (startDatePreenchido0 > endDatePreenchido0) {
                              return Promise.reject(
                                new Error("Data inicial e maior que data final")
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <DatePicker
                        name="dataFinalVigencia"
                        placeholder="Digite a data"
                        allowClear
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Form.Item
                label="Periodo Final:"
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.valueIni !== curValues.valueIni ||
                    prevValues.valueEnd !== curValues.valueEnd
                  );
                }}
              >
                {({ getFieldValue }) => {
                  const startDatePreenchido0 = getFieldValue("valueIni");
                  const endDatePreenchido0 = getFieldValue("valueEnd");

                  return (
                    <Form.Item
                      name="valueEnd"
                      dependencies={["valueIni"]}
                      rules={[
                        {
                          required: startDatePreenchido0 && true,
                          message: "Por Favor, insira data",
                        },
                        {
                          validator: async () => {
                            if (endDatePreenchido0) {
                              if (endDatePreenchido0 < startDatePreenchido0) {
                                return Promise.reject(
                                  new Error(
                                    "Data final e menor que data inicial."
                                  )
                                );
                              }
                            }
                          },
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={(d) => d.isBefore(startDatePreenchido0)}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </QueryFilter>

            <Table
              className="table-antd"
              onChange={onChangeTable}
              columns={columns}
              dataSource={data?.content}
              pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: data?.size,
                total: data?.totalElements,
                showSizeChanger: true,
                position: ["bottomCenter"],
              }}
              rowKey="id"
              loading={loading}
            />
          </Card>

          <Card>
            <div className="row col-md-12 button-right p-0 m-0">
              <Button
                loading={recordLoadingButton}
                type="primary"
                onClick={() => gerarRelatorio()}
              >
                Gerar Relatório
              </Button>
            </div>
          </Card>
        </PageContainer>
      </Secured>
    </>
  );
};

export default RelatorioGerencial;
