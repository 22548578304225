import React, { useState, useEffect, useMemo } from "react";
import { Permissions } from "../../Constantes/permissions.constants";
import { Secured } from "../../component";
import { PageContainer } from "@ant-design/pro-layout";
import InputCPF from "../../component/InputCpf";
import {
  Form,
  Button,
  Card,
  Select,
  Table,
  Space,
  Input,
  DatePicker,
  Modal,
} from "antd";

import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import service from "./service";
import { Link } from "react-router-dom";
import { QueryFilter } from "@ant-design/pro-form";
import { formatDate, formatCpf } from "../../helper/util";

const AdministracaoAuditoria = () => {
  const [data, setData] = useState();
  const [situacao, setSituacao] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState();
  const [isModalVisible, setModalVisible] = useState(false);
  const [auditoria, setAuditoria] = useState();
  const [endDateRequired, setEndDateRequired] = useState(false);
  const [startDateRequired, setStartDateRequired] = useState(false);
  const pagination = {
    page: 0,
    size: 10,
  };

  const actions = { 1: "SELECT", 2: "INSERT", 3: "DELETE", 4: "UPDATE" };

  const columns = useMemo(
    () => [
      {
        title: "Data e Hora",
        dataIndex: "data",
        key: "data",
        sorter: true,
        width: "200px",
        render: (text) => (
          <span>{text && formatDate(text, "DD/MM/YYYY hh:mm:ss")}</span>
        ),
      },
      {
        title: "Transação",
        dataIndex: "transacao",
        key: "transacao",
        width: "200px",
      },
      {
        title: "Usuário",
        dataIndex: "usuario",
        key: "usuario",
        render: (text) => formatCpf(text),
      },
      {
        title: "Entidade",
        dataIndex: "entidade",
        key: "entidade",
        width: "200px",
      },
      {
        title: "Ação",
        dataIndex: "acao",
        key: "acao",
        width: "200px",
      },
      {
        title: "Detalhe",
        width: "100px",
        render: (text, record) => (
          <Space size={10}>
            <Button
              type="text"
              icon={<EyeOutlined />}
              onClick={() => {
                setModalVisible(true);
                setAuditoria(record);
              }}
            ></Button>
          </Space>
        ),
      },
    ],
    []
  );

  const getAudits = (parametros) => {
    if (!parametros.searchQueries) {
      parametros.searchQueries = [
        {
          field: "aplicacao",
          operator: "LK",
          value: "censo-api",
          fieldType: "text",
        },
      ];
    }

    setLoading(true);

    service
      .getAudits(parametros)
      .then((resp) => {
        setData(resp.data);
      })
      .finally(() => setLoading(false));
  };

  const onSearch = async (values) => {
    let searchQueries = [];
    for (const [key, value] of Object.entries(values)) {
      if (key === "dataIni") {
        searchQueries.push({
          field: "data",
          fieldType: "date",
          operator: "BT",
          valueEnd: values.dataEnd,
          valueIni: values.dataIni,
        });
      } else if (key !== "dataIni" && key !== "dataEnd") {
        searchQueries.push({
          field: key,
          fieldType: "text",
          operator: "LK",
          value: key === "usuario" ? value.replace(/\D/g, "") : value,
        });
      }
    }

    searchQueries.push({
      field: "aplicacao",
      operator: "LK",
      value: "censo-api",
      fieldType: "text",
    });

    setFilterValues({
      pagination,
      searchQueries: searchQueries,
    });
  };

  const onChangeTable = function (pagination, filters, sorte) {
    var controlTable = {
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: "",
    };
    controlTable.sort =
      sorte.order != undefined
        ? sorte.columnKey + "," + sorte.order.substr(0, sorte.order.length - 3)
        : "";
    setFilterValues({
      ...filterValues,
      pagination: controlTable,
    });
  };

  React.useEffect(() => {
    if (filterValues) getAudits(filterValues);
  }, [filterValues]);

  useEffect(() => {
    const pagination = {
      page: 0,
      size: 10,
    };
    getAudits({
      pagination,
      searchQueries: [
        {
          field: "aplicacao",
          operator: "LK",
          value: "censo-api",
          fieldType: "text",
        },
      ],
    });
  }, []);

  return (
    <>
      <Secured hasAnyRole={Permissions.AUDITORIA.TELA} isShowErro403={true}>
        <PageContainer>
          <h1>Auditorias</h1>
          <Card
            title="Consultar Auditorias"
            style={{
              fontSize: "20px",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <QueryFilter
              onFinish={onSearch}
              layout="vertical"
              defaultCollapsed
              className="filtro"
              span={8}
            >
              <Form.Item
                label="Usuário:"
                name="usuario"
                rules={[{ validator: InputCPF.Validator }]}
              >
                <InputCPF placeholder="Digite o N° do Cpf" showSearch={false} />
              </Form.Item>

              <Form.Item label="Transação:" name="transacao">
                <Input placeholder="Digite a Transação" allowClear />
              </Form.Item>

              <Form.Item label="Ação:" name="acao">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(actions).map((value) => ({
                    label: actions[value],
                    value: actions[value],
                  }))}
                />
              </Form.Item>

              <Form.Item
                label="Data e Hora Inicial:"
                name="dataIni"
                dependencies={["dataEnd"]}
                rules={[
                  {
                    required: startDateRequired,
                    message: "Por favor, insira a Data!",
                  },
                  ({ getFieldValue }) => ({
                    async validator(_, value) {
                      const endDate = getFieldValue("dataEnd");
                      const startDate = getFieldValue("dataIni");
                      if (
                        value &&
                        (endDate === undefined || endDate === "" || !endDate)
                      ) {
                        setEndDateRequired(true);
                      } else if (value && startDate > endDate) {
                        return Promise.reject(
                          new Error("Data/Hora e maior que Data/Hora Final!")
                        );
                      } else setEndDateRequired(false);
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY H:mm:ss"
                  style={{ height: "42px", width: "100%" }}
                  showTime
                />
              </Form.Item>

              <Form.Item
                label="Data e Hora Final:"
                name="dataEnd"
                dependencies={["dataIni"]}
                rules={[
                  {
                    required: endDateRequired,
                    message: "Por favor, insira a Data!",
                  },
                  ({ getFieldValue }) => ({
                    async validator(_, value) {
                      const startDate = getFieldValue("dataIni");
                      const endDate = getFieldValue("dataEnd");
                      if (
                        value &&
                        (startDate === undefined ||
                          startDate === "" ||
                          !startDate)
                      ) {
                        setStartDateRequired(true);
                      } else if (value && endDate < startDate) {
                        return Promise.reject(
                          new Error("Data/Hora e menor que Data/Hora Inicial!")
                        );
                      } else setStartDateRequired(false);
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY H:mm:ss"
                  style={{ height: "42px", width: "100%" }}
                  showTime
                />
              </Form.Item>
            </QueryFilter>

            <Table
              onChange={onChangeTable}
              columns={columns}
              dataSource={data?.content}
              pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: data?.size,
                total: data?.totalElements,
                showSizeChanger: false,
                position: ["bottomCenter"],
              }}
              rowKey="id"
              loading={loading}
            />
          </Card>
          <Modal
            visible={isModalVisible}
            title="Detalhes da Auditoria"
            onCancel={() => setModalVisible(false)}
            okButtonProps={{
              style: {
                display: "none",
              },
            }}
            cancelButtonProps={{
              style: {
                display: "none",
              },
            }}
          >
            {JSON.stringify(auditoria?.dados)}
          </Modal>
        </PageContainer>
      </Secured>
    </>
  );
};

export default AdministracaoAuditoria;
