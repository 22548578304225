export const Permissions = {
  RECADASTRAMENTO: {
    MENU: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_ATENDENTE"],
    TODOS_PAIS: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_ATENDENTE_GOIASPREV"],
    TELA: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_ATENDENTE"],
  },
  RELATORIOS: {
    MENU: ["ROLE_CENSO_GERENCIA"],
    TELA: ["ROLE_CENSO_GERENCIA"],
  },
  USUARIO: {
    MENU: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_CALENDARIO"],
    TELA: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_CALENDARIO"],
  },
  AGENDAMENTO: {
    MENU: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_AGENDAR_BLOQUEIO"],
    TELA: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_AGENDAR_BLOQUEIO"],
  },
  AUDITORIA: {
    MENU: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_CALENDARIO"],
    TELA: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_CALENDARIO"],
  },
  CALENDARIOSCENSO: {
    MENU: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_CALENDARIO"],
    TELA: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_CALENDARIO"],
  },
  VALIDARCERTIDAO: {
    MENU: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_VALIDAR_CERTIDAO"],
    TELA: ["ROLE_CENSO_GERENCIA", "ROLE_CENSO_VALIDAR_CERTIDAO"],
  },
};
