import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom';

const RouteChildren = ({
    routeChildren,
    ...props
}) => {

    let { path } = useRouteMatch();
    return (
        <>
            <Switch>
                {routeChildren && routeChildren.map((child, index) => <Route key={index} exact={child.exact} component={child.component} path={`${path}` + child.path} />)}
            </Switch>
        </>
    )
}

export default RouteChildren;