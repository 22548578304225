import React from 'react';

export const AppContext = React.createContext(null);

AppContext.displayName = 'Application Context';

export const withAppContext = (Component) => (props) => (
  <AppContext.Consumer>
    {(contextValues) => <Component {...contextValues} {...props} />}
  </AppContext.Consumer>
);

export default AppContext;
