import React from "react";
import "./App.scss";
import "./App.less";
import { ErrorBoundary, SecurityProvider } from "./component";
import config from "./config";
import Home from "./page/Home";
import MainHomeProvider from "./state/Home/Provider";
import LoadingProvider from "./state/Loading/Provider";
import MenuProvider from "./state/Menu/Provider";
import MessageProvider from "./state/Message/Provider";
import { CalendarioRecadastramentoProvider } from "./state/CalendarioRecadastramento/CalendarioRecadastramentoProvider";

const securityConfig = {
  realm: "goiasprev",
  clientId: "censo",
  url: config.KEYCLOAK_AUTH,
};
const App = () => {
  const isHomolog = () => {
    if (process.env.REACT_APP_ENV === "homolog") {
      return true;
    }
    return false;
  };

  return (
    <div id="app" className={isHomolog() ? "light-theme" : "dark-theme"}>
      <ErrorBoundary>
        <SecurityProvider config={securityConfig} loginRequired>
          <LoadingProvider>
            <CalendarioRecadastramentoProvider>
              <MenuProvider>
                <MessageProvider>
                  <MainHomeProvider>
                    <Home />
                  </MainHomeProvider>
                </MessageProvider>
              </MenuProvider>
            </CalendarioRecadastramentoProvider>
          </LoadingProvider>
        </SecurityProvider>
      </ErrorBoundary>
    </div>
  );
};

export default App;
