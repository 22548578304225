import React, { Fragment, useEffect, useState } from 'react';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css';
import Services from './services';

import './styles.scss';
import config from '../../config';
import { useUsuarioLogado } from '../../component';
import { TypeIcon } from './typeIcon';

const Erro403 = ({ fallback }) => {
    const { userName } = useUsuarioLogado();
    const [sistemas, setSistemas] = useState([]);

    useEffect(() => {
        let fetch = false;
        Services.getSistemas(config.SA_API, userName).then((response) => {
            if (!fetch) {
                setSistemas(response);
            }
        }).catch(console.log);

        return () => {
            fetch = true;
        }
    }, []); // eslint-disable-line

    const getIcon = (sistema) => {
        let icon = '';
        Object.keys(TypeIcon).map((item) => {
            if (TypeIcon[item].value == sistema.clientId) { //eslint-disable-line
                icon = "fas " + TypeIcon[item].icon;
            }
            return item;
        });
        return icon;
    }

    return (
        <Fragment>
            <Painel classPainel='row justify-content-md-center painel-erro' classConteudo='col-lg-3'>
                <div className='row justify-content-md-center text-center conteudo'>
                    <i className='fas fa-user-lock fa-5x' />
                    <div className='col-md-12 mt-4'>
                        <span className='title-erro'>Erro 403</span>
                    </div>
                    <p className='col-md-12'>{fallback ? fallback : 'Usuário sem permissão de acesso à funcionalidade.'}</p>
                    <hr className='col-md-12' /> 
                     <p className='font-weight-bold'>Acesse outra funcionalidade</p>
                    
                    <div className="funcionalidades-itens col-md-12">
                        {
                            sistemas.map((item, index) => <div className="funcionalidades-item">
                                <a href={item.baseUrl}>
                                    <span className="funcionalidades-item-icon"><i className={getIcon(item)}/></span>
                                    <span className="funcionalidades-item-title">{item.clientId}</span>
                                </a>
                            </div>
                            ) 
                        }
                    </div>
                </div>
            </Painel>
        </Fragment>
    )
}

export default Erro403;
