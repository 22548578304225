import React from "react";
import { Col, Row, Tooltip } from "antd";
import ItemStatus from "./ItemStatus";
import moment from "moment";
import statusDosBeneficios from "./statusDosBeneficios";

const BeneficioStatus = ({ item, index }) => {
  const informacoesDoTooltip = (item) => {
    return statusDosBeneficios[item.status]?.tooltip ? (
      <div className="box-tootip-message">
        <p>{item.status}</p>
        <p>
          Data fim da vigência:{" "}
          {item?.dataFinalVigencia
            ? moment(item?.dataFinalVigencia, "DD/MM/YYYY").format("DD/MM/YYYY")
            : ""}
        </p>
      </div>
    ) : (
      ""
    );
  };

  return (
    <Row style={{ padding: "0.5em" }}>
      <Col
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 4 }}
        xl={{ span: 4 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip placement="right" title={informacoesDoTooltip(item)}>
          <ItemStatus status={item.status} style={{ width: "100%" }} />
        </Tooltip>
      </Col>
      <Col span={20}>
        <div className="beneficio-titulo">{item.aposentadoria}</div>
        {item.instituidor ? (
          <div className="beneficio-subTitulo">
            INSTITUIDOR: {item.instituidor}
          </div>
        ) : (
          <div className="beneficio-subTitulo">{item.instituicao}</div>
        )}
      </Col>
    </Row>
  );
};

export default BeneficioStatus;
