import React, { useCallback } from "react";
import "../style.scss";
import Painel from "gp-painel";
import { Secured } from "../../../component";
import { Permissions } from "../../../Constantes/permissions.constants";
import { Table, Space, Button, message, Modal } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import services from "../services";
import { formatarData } from "../../../helper/util";
import { Message } from "../../../Constantes/message.constants";
import { useCalendarioRecadastramento } from "../../../state/CalendarioRecadastramento/CalendarioRecadastramentoProvider";

const sortString = (a, b) => (a === b ? 0 : a < b ? -1 : 1);

const CalendariosCenso = () => {
  const [data, setData] = React.useState([]);
  const { calendariosCadastrados, setCalendariosCadastrados } =
    useCalendarioRecadastramento();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 60,
    },
    {
      title: "Regime",
      dataIndex: "regime",
      key: "regime",
      width: 150,
    },
    {
      title: "Inicio",
      dataIndex: "dataInicioVigencia",
      key: "dataInicioVigencia",
      width: 150,
      render: (text) => formatarData(text).format("DD/MM/YYYY"),
      sorter: {
        compare: (a, b) =>
          sortString(a.dataInicioVigencia, b.dataInicioVigencia),
      },
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      key: "descricao",
      sorter: {
        compare: (a, b) => sortString(a.descricao, b.descricao),
      },
    },
    {
      title: "Ações",
      key: "acoes",
      width: 50,
      render: (text, record) => (
        <Space size={10}>
          <Link to={`/calendario/salvar/${record.id}`}>
            <Button type="text" icon={<EditOutlined />}></Button>
          </Link>
          <Button
            danger
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => verificarExcluirCalendario(record)}
          ></Button>
        </Space>
      ),
    },
  ];

  function obterListaCalendario() {
    services
      .obterListaCalendario()
      .then((resultado) => resultado.data)
      .then(setData);
  }

  React.useEffect(() => {
    obterListaCalendario();
  }, []);

  const verificarExcluirCalendario = (item) =>
    Modal.confirm({
      title: "Atenção",
      content: `Deseja realmente EXCLUIR esse calendário?`,
      okText: "Sim",
      onOk: () => excluirCalendario(item),
    });

  const excluirCalendario = useCallback((item) =>
    services.excluirCalendario(item.id).then(() => {
      message.success(
        Message.MSG018_GERAL_EXCLUIDO_COM_SUCESSO.replace("{0}", "Calendário"),
        3
      );
      obterListaCalendario();
    })
  );

  React.useEffect(() => {
    setCalendariosCadastrados(data);
    localStorage.setItem("calendariosCadastrados", JSON.stringify(data));
  }, [data, setCalendariosCadastrados, excluirCalendario]);

  return (
    <Secured
      hasAnyRole={Permissions.CALENDARIOSCENSO.TELA}
      isShowErro403={true}
    >
      <Painel titulo="Calendários Recadastramento">
        <div className="container-botoes">
          <Link to="/calendario/salvar">
            <Button
              className="button-space"
              type="primary"
              icon={<PlusOutlined />}
            >
              Novo Calendário
            </Button>
          </Link>
        </div>
        <Table columns={columns} dataSource={data} rowKey="id" />
      </Painel>
    </Secured>
  );
};

export default CalendariosCenso;
