import { useSecurityContext } from "./useSecurityContext";

export const useToken = () => {
  const { getToken, getTokenParsed, tokenRefreshedAt } = useSecurityContext();

  const token = getToken();
  const tokenParsed = getTokenParsed();
  return { token, tokenParsed, tokenRefreshedAt };
};

export default useToken;
