import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import {Row, ConfigProvider, Form, DatePicker } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import moment from 'moment';
import 'moment/locale/pt-br';


moment.locale('pt-br');
Row.defaultProps = {gutter: 12};
Form.defaultProps = { layout: "vertical" };
DatePicker.defaultProps = { format: "DD/MM/YYYY" };


ReactDOM.render(
    <Router>
        <ConfigProvider locale={ptBR}>
            <App />
        </ConfigProvider>
    </Router>
    , document.getElementById('root'));