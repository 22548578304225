import React from 'react';
import { AppContext } from './AppContext';

const AppProvider = ({ children, ...props }) => {
  const [state, setState] = React.useState(props || {});
  const updateAppContext = (values) => setState({ ...state, ...values });
  const setCurrentError = (currentError) => updateAppContext({ currentError });
  const handleError = (currentError) => setCurrentError(currentError);
  const values = { ...state, updateAppContext, setCurrentError, handleError };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppProvider;
