import {removeMask} from "./mask";

export function isOK(expression){
    if(expression === undefined) return "form-control"
    return "form-control is-invalid"
}

export const validateCpf = cpf => {
    cpf = removeMask(cpf)
    if (cpf === null || cpf === '' || cpf.length !== 11) return false
    let sum = 0
    let rest
    if (cpf === "00000000000") return false
    if (cpf === "11111111111") return false
    if (cpf === "22222222222") return false
    if (cpf === "33333333333") return false
    if (cpf === "44444444444") return false
    if (cpf === "55555555555") return false
    if (cpf === "66666666666") return false
    if (cpf === "77777777777") return false
    if (cpf === "88888888888") return false
    if (cpf === "99999999999") return false

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    rest = (sum * 10) % 11
    if (rest === 10 || rest === 11) rest = 0
    if (rest !== parseInt(cpf.substring(9, 10))) return false
    sum = 0
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    rest = (sum * 10) % 11
    if (rest === 10 || rest === 11) rest = 0
    if (rest !== parseInt(cpf.substring(10, 11))) return false
    return true
}

export function convertErrorsToList(errors) {
    let list = []
    Object.keys(errors).map(k => {
        return list.push(errors[k])
    })
    return list;
}

export const validateEmail = email => {
    if (email != undefined) { // eslint-disable-line
        const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-zA-Z]+[.][a-zA-Z]+/g;
        return pattern.test(email);
    } else {
        return true;
    }
}

export const isObrigatorioArquivoComRegEXP = (parametro, valor)=>{
    return new RegExp(`(^|;)${parametro}(;|$)`).test(valor);
}
