import React from "react";
import statusDosBeneficios from "./statusDosBeneficios";
const ItemStatus = (props) => (
  <span
    {...props}
    className={`badge badge-pill ${
      statusDosBeneficios[props.status]?.class
        ? statusDosBeneficios[props.status]?.class
        : "badge-success"
    }`}
  >
    {statusDosBeneficios[props.status]?.nome
      ? statusDosBeneficios[props.status]?.nome
      : statusDosBeneficios.ATIVO.sigla}
  </span>
);

export default ItemStatus;
