import axios from "axios";
import config from "../../config";

const AUDITORIA_API = config.AUDITORIA_API;

const getAudits = (data) =>
  axios.post(`${AUDITORIA_API}auditorias/filter`, {
    pagination: data.pagination,
    searchQueries: data.searchQueries,
  });

export default {
  getAudits,
};
