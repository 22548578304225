import React from "react";
import { Col, DatePicker, Input, Row, Form } from "antd";

const FORMAT_MONTH = 'MMMM';

const ItemPeriodo = ({
    name
}) => {
    return (
            <Row>
                <Form.Item name={[name, "id"]} hidden >
                    <Input />
                </Form.Item>
                <Col span={8}>
                    <Form.Item name={[name, "mesAniversario"]} rules={[{required:true}]}>
                        <DatePicker className="uppercase-datepicker" picker="month" format={FORMAT_MONTH} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={[name, "mesRecadastramentoIni"]} rules={[{required:true}]}>
                        <DatePicker className="uppercase-datepicker" picker="month" format={FORMAT_MONTH}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={[name, "mesRecadastramentoFim"]} rules={[{required:true}]}>
                        <DatePicker className="uppercase-datepicker" picker="month" format={FORMAT_MONTH}/>
                    </Form.Item>
                </Col>
            </Row>);
}

export default ItemPeriodo;