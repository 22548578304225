import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import LoadingContext from "../state/Loading/Context";
import * as LoadingActions from "../state/Loading/actions";

const ListeningRoute = () => {

    const { loadingDispatch } = useContext(LoadingContext);
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            loadingDispatch(LoadingActions.removeLoading);
        })
    }, [history]); // eslint-disable-line

    return (
        <></>
    )
}

export default ListeningRoute;