import React, { useContext, useEffect } from "react";

import MessageContext from "../../state/Message/Context";

import './style.scss';
import * as MessageActions from "../../state/Message/actions";

const Alert = ({ classAlert }) => {
    const { message, messageDispatch } = useContext(MessageContext);
    const handleClose = (index) => messageDispatch(MessageActions.removeMessage(index));
    const handleCloseAllMessage = () => messageDispatch(MessageActions.removeAllMessage);

    useEffect(() => {
        let timer = setTimeout(() => handleCloseAllMessage(), 7000);
        return () => {
            clearTimeout(timer);
        }
    }, [message]); // eslint-disable-line

    return (
        message ? message.values.reverse().map((item, indexValue) => (
            indexValue < 3 &&
            <div id="alert-redux" className={"col-md-12 " + classAlert} key={indexValue}>
                <div className={item.type + ' pt-3 pb-3'} role="alert">
                    <button type="button" className="close p-2" aria-label="Fechar" onClick={() => handleClose(indexValue)} >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <i className={item.icon + ' mr-2 '}></i> {item.messages.length > 1 ? item.title : item.messages[0]}
                    {item.messages.length > 1 && item.messages.map((m, index) => {
                        return (
                            <ul key={index} className="my-0 pl-4">
                                <li>{m}</li>
                            </ul>
                        );
                    })}
                </div>
            </div>
        ))
            : <></>
    )

}

export default Alert;