import React from 'react';
import Painel from 'gp-painel';
import 'gp-painel/dist/index.css'

class ErrorBoundary extends React.Component {
  static getDerivedStateFromProps({ currentError }) {
    return currentError ? { currentError, catched: false } : null;
  }

  static getDerivedStateFromError(currentError) {
    return { currentError, catched: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { currentError } = this.state;
    return (
      (!currentError && (nextProps.currentError || nextState.currentError)) ||
      (currentError && !(nextProps.currentError && nextState.currentError))
    );
  }

  componentDidCatch(currentError) {
    this.setState({ currentError, catched: true });
  }

  clearState() {
    this.setState({ currentError: null, catched: null });
  }

  render() {
    const { currentError, catched } = this.state;
    const { children, renderError } = this.props;
    const renderProps = {
      catched,
      children,
      currentError,
      clearState: () => this.clearState(),
    };
    return (
      <>
        {!catched && children}
        {!!currentError && renderError(renderProps)}
      </>
    );
  }
}

const renderError = ({ currentError, clearState }) => (
  <div className="painel-error-container">
    <div className="container-fluid m-0 p-0 row conteudo-error ">
      <Painel classPainel='row justify-content-md-center painel-erro' classConteudo='col-lg-12'>
        <div className='row justify-content-md-center text-center conteudo'>
          <i className='fas fa-exclamation-circle fa-5x' />
          <div className='col-md-12 mt-4'>
            <span className='title-erro'>Erro</span>
          </div>
          <p className='col-md-12 font-weight-bold'>Deculpe Algo deu errado</p>
          <hr className='col-md-12' />
        </div>
        <div className="btn-error">
          <button type="button" onClick={clearState} className="btn btn-outline-dark btn-sm mr-2">
            Voltar
          </button>
        </div>
      </Painel>
    </div>

  </div>

);

ErrorBoundary.defaultProps = {
  renderError,
};

export default ErrorBoundary;
